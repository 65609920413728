import React from "react"
import star from '../../images/icons/star.png'
import include from '../../images/icons/include.png'
import not_include from '../../images/icons/not_include.png'
import styles from './prices.module.css'
import { FormattedMessage } from "react-intl";

const prices = [
  {
    title: <FormattedMessage id="prices.basic.title" />,
    transactions: <FormattedMessage id="prices.basic.transactions" />,
    price: <FormattedMessage id="prices.basic.price" />,
    autocomplete: include,
    hygiene: include,
    phone: include,
    validation: include,
    reverse: include,
    catchment: not_include,
    poi: not_include,
    points: not_include,
    potential: star,
    maps: star,
    data: star
  },
  {
    title: <FormattedMessage id="prices.basic_plus.title" />,
    transactions: <FormattedMessage id="prices.basic_plus.transactions" />,
    price: <FormattedMessage id="prices.basic_plus.price" />,
    autocomplete: include,
    hygiene: include,
    phone: include,
    validation: include,
    reverse: include,
    catchment: not_include,
    poi: not_include,
    points: not_include,
    potential: star,
    maps: star,
    data: star
  },
  {
    title: <FormattedMessage id="prices.pro.title" />,
    transactions: <FormattedMessage id="prices.pro.transactions" />,
    price: <FormattedMessage id="prices.pro.price" />,
    autocomplete: include,
    hygiene: include,
    phone: include,
    validation: include,
    reverse: include,
    catchment: include,
    poi: include,
    points: include,
    potential: star,
    maps: star,
    data: star
  },
  {
    title: <FormattedMessage id="prices.pro_plus.title" />,
    transactions: <FormattedMessage id="prices.pro_plus.transactions" />,
    price: <FormattedMessage id="prices.pro_plus.price" />,
    autocomplete: include,
    hygiene: include,
    phone: include,
    validation: include,
    reverse: include,
    catchment: include,
    poi: include,
    points: include,
    potential: star,
    maps: star,
    data: star
  },
  {
    title: <FormattedMessage id="prices.enterprice.title" />,
    transactions: <FormattedMessage id="prices.enterprice.transactions" />,
    price: star,
    autocomplete: star,
    hygiene: star,
    phone: star,
    validation: star,
    reverse: star,
    catchment: star,
    poi: star,
    points: star,
    potential: star,
    maps: star,
    data: star
  }
]

const isDesktop = typeof window !== `undefined` && window.innerWidth > 768;

class Prices extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      chosen: 0
    };
  }

  handleChipClick = (chosen) => {
    this.setState({
      chosen
    })
  }

  render () {
    return (
      <>
        {!isDesktop ? (
          <div className="row">
            <div className={"col-12 " + styles.chipsContainer}>
              {prices.map((item, key) => {
                return <span className={styles.chips + (key === this.state.chosen ? ' ' + styles.active : ``)} key={key} onClick={() => this.handleChipClick(key)}>{item.title}</span>
              })}
            </div>
          </div>
        ) : ''}
        <div className="row">
          <div className="col-12">
            <table className={styles.priceTable}>
              <tbody>
                <tr>
                  <th></th>
                  {prices.map((item, key) => {
                    return isDesktop || key === this.state.chosen ? <th key={key}>{item.title}</th> : null
                  })}
                </tr>
                <tr>
                  <td><FormattedMessage id="prices.netto" /></td>
                  {prices.map((item, key) => {
                    return isDesktop || key === this.state.chosen ? (
                      <td className={styles.value + (key % 2 === 1 ? " " + styles.bold : '')} key={key}>
                        {typeof item.price === 'object' ? item.price : <img src={item.price} alt="" />}</td>
                    ) : null
                  })}
                </tr>
                <tr className={styles.bottomSpace}>
                  <td><FormattedMessage id="prices.max" /></td>
                  {prices.map((item, key) => {
                    return isDesktop || key === this.state.chosen ? (
                      <td className={styles.priceVal} key={key}>
                        {key === prices.length - 1 ? <small><FormattedMessage id="prices.above" /></small> : ''}
                        {typeof item.transactions === 'object' ? item.transactions : <img src={item.transactions} alt="" />}
                        {key === prices.length - 1 ? <small><FormattedMessage id="prices.premise" /> /<br /> <FormattedMessage id="prices.commercial" /></small> : ''}
                      </td>
                    ) : null
                  })}
                </tr>
                <tr>
                  <td colSpan="6" className={styles.yellow}><FormattedMessage id="prices.quality" /></td>
                </tr>
                <tr className={styles.topSpace}>
                  <td><FormattedMessage id="prices.quality.autocomplete" /></td>
                  {prices.map((item, key) => {
                    return isDesktop || key === this.state.chosen ? <td key={key}><img src={item.autocomplete} alt="" /></td> : null
                  })}
                </tr>
                <tr>
                  <td><FormattedMessage id="prices.quality.hygiene" /></td>
                  {prices.map((item, key) => {
                    return isDesktop || key === this.state.chosen ? <td key={key}><img src={item.hygiene} alt="" /></td> : null
                  })}
                </tr>
                <tr>
                  <td><FormattedMessage id="prices.quality.phone" /></td>
                  {prices.map((item, key) => {
                    return isDesktop || key === this.state.chosen ? <td key={key}><img src={item.phone} alt="" /></td> : null
                  })}
                </tr>
                <tr>
                  <td><FormattedMessage id="prices.quality.validation" /></td>
                  {prices.map((item, key) => {
                    return isDesktop || key === this.state.chosen ? <td key={key}><img src={item.validation} alt="" /></td> : null
                  })}
                </tr>
                <tr className={styles.bottomSpace}>
                  <td><FormattedMessage id="prices.quality.reverse" /></td>
                  {prices.map((item, key) => {
                    return isDesktop || key === this.state.chosen ? <td key={key}><img src={item.reverse} alt="" /></td> : null
                  })}
                </tr>
                <tr>
                  <td colSpan="6" className={styles.yellow}><FormattedMessage id="prices.maps" /></td>
                </tr>
                <tr className={styles.topSpace}>
                  <td><FormattedMessage id="prices.maps.catchment" /></td>
                  {prices.map((item, key) => {
                    return isDesktop || key === this.state.chosen ? <td key={key}><img src={item.catchment} alt="" /></td> : null
                  })}
                </tr>
                <tr>
                  <td><FormattedMessage id="prices.maps.poi" /></td>
                  {prices.map((item, key) => {
                    return isDesktop || key === this.state.chosen ? <td key={key}><img src={item.poi} alt="" /></td> : null
                  })}
                </tr>
                <tr className={styles.bottomSpace}>
                  <td><FormattedMessage id="prices.maps.points" /></td>
                  {prices.map((item, key) => {
                    return isDesktop || key === this.state.chosen ? <td key={key}><img src={item.points} alt="" /></td> : null
                  })}
                </tr>
                <tr>
                  <td colSpan="6" className={styles.yellow}><FormattedMessage id="prices.data" /></td>
                </tr>
                <tr className={styles.topSpace}>
                  <td><FormattedMessage id="prices.data.potential" /></td>
                  {prices.map((item, key) => {
                    return isDesktop || key === this.state.chosen ? <td key={key}><img src={item.potential} alt="" /></td> : null
                  })}
                </tr>
                <tr>
                  <td><FormattedMessage id="prices.data.maps" /></td>
                  {prices.map((item, key) => {
                    return isDesktop || key === this.state.chosen ? <td key={key}><img src={item.maps} alt="" /></td> : null
                  })}
                </tr>
                <tr>
                  <td><FormattedMessage id="prices.data.data" /></td>
                  {prices.map((item, key) => {
                    return isDesktop || key === this.state.chosen ? <td key={key}><img src={item.data} alt="" /></td> : null
                  })}
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className={styles.blue}>
              <img src={star} alt="" /> <FormattedMessage id="prices.contact" />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className={styles.info}>
              <p><FormattedMessage id="prices.info1" /></p>
              <p><FormattedMessage id="prices.info2" /></p>
            </div>
          </div>
        </div>
      </>
    )
  }
}



export default Prices
